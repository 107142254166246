import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { PiTrademarkRegisteredLight } from "react-icons/pi";


const ReferralButton = ({ setReffCode }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const applyReferralCode = (code) => {
    if (typeof setReffCode === "function") {
      setReffCode(code); // Directly set the referral code
    } else {
      console.error("setReffCode is not a function");
    }
    setShow(false); // Close the modal after setting the code
  };

  return (
    <>
      <Button
        variant="danger"
        onClick={handleShow}
        className='refBtnHelp'
    
      >
        <PiTrademarkRegisteredLight className='me-1 btn-info' />
      </Button>


      

      <Modal show={show} onHide={handleClose} centered>
  <Modal.Header style={{ display: "block" }}>
    <Modal.Title><center>Referral Code Required</center></Modal.Title>
  </Modal.Header>
  <Modal.Body className="text-center">
  <p style={{ textAlign: "justify" }}>
  To purchase a node, you'll need a referral code. 
  </p>
  <p style={{ textAlign: "justify" }}>Have a referral code? Use it to reward your referrer and support their earnings.<br />
  <small>(Referral codes from users with higher levels provide additional benefits.)</small>
  </p>


  <p style={{ textAlign: "justify" }}>Or, use the platform code to support the WSHARE profit centre on WaveSwaps:</p>
  <div className="text-center">
    <Button
      variant="info"
      onClick={() => applyReferralCode("WSHARE")}
      className="use-code-btn btn-referral"
    >
      Use WSHARE
    </Button>
    </div>
    <div className="text-center mt-3">
    <small>
      By entering or using a referral code, you agree to platform's 
      <a 
        href="https://docs.waveswaps.com/ws-official/referral-protocol" 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ color: "#007bff", textDecoration: "underline" }}
      > referral protocol.
      </a>
    </small>
  </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" className="buton" onClick={handleClose}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </>
  );
};

export default ReferralButton;
