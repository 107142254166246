import React, { useEffect, useState } from 'react';
import { BrowserProvider, Contract } from 'ethers';
import NodesABI from '../abis/NodesABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Spinner, Alert, Button } from 'react-bootstrap';
import MergeContainer from './MergeContainer';
import DraggableNode from './DraggableNode';
import DraggableCore from './DraggableCore';
import CraftBook from './CraftBook';
import { useNavigate } from 'react-router-dom';
import ConnectButton from './ConnectButton';

const NodeMergeSection = ({ provider, address, isConnected, networkId, switchNetwork }) => {
  const [ownedNFTs, setOwnedNFTs] = useState([]);
  const [distinctOwnedNFTs, setDistinctOwnedNFTs] = useState([]);
  const [ownedNftTypeCount, setOwnedNftTypeCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [userLevel, setUserLevel] = useState(1);
  const [node1, setNode1] = useState(null);
  const [node2, setNode2] = useState(null);
  const [node3, setNode3] = useState(null);
  const [node4, setNode4] = useState(null);
  const navigate = useNavigate();


  const openNodesList = () => {
    navigate('/list');
  };

  const hideCard = (tokenId) => {
    let nftsArr = [];
    for (let i = 0; i < ownedNFTs.length; i++) {
      let nft = ownedNFTs[i];
      if (nft.tokenId === tokenId) {
        nft.visible = false;
        if(ownedNftTypeCount[nft.tokenType] <=0){
          return false;
        }
        
        ownedNftTypeCount[nft.tokenType] -= 1;
        
      }
      nftsArr.push(nft);
    }
    setOwnedNFTs(nftsArr);
    return true;
  };

  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };

  const fetchOwnedNFTs = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
      const account = await signer.getAddress();
      const nftsForUser = await contract.getAllNFTsForUser(account);
      console.log(nftsForUser);
      const serialized = JSON.stringify(nftsForUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const nfts = JSON.parse(serialized);
      console.log(nfts);
      // Fetch all NFT data in parallel
      const nftDataPromises = nfts.map(async (nft) => {
        const response = await fetch(nft[3]);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        const tokenType = await collectionContract.tokenTypes(parseInt(nft[1]));

        return {
          collectionAddress: nft[0],
          tokenId: nft[1],
          name: nft[2],
          imageURL: json.image,
          tokenType: tokenType.toString(),
          visible: true
        };
      });

      const nftsArr = await Promise.all(nftDataPromises);

      let distinctArr = [];
            let nftTypeCount = {};
    
            nftsArr.forEach((temp) => {
              
    
                if (!nftTypeCount[temp.tokenType]) {
                    nftTypeCount[temp.tokenType] = 0;
                    
                      distinctArr.push(temp);
                    
                }
                nftTypeCount[temp.tokenType] += 1;
    
            });
      console.log(nftsArr);
      setOwnedNFTs(nftsArr);
      setDistinctOwnedNFTs(distinctArr);
      setOwnedNftTypeCount(nftTypeCount);
      await getUserLevel();
    } catch (e) {
      console.log("owned nfts error");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  const handleNodeClick = (nft) => {
    if (!node1) {
      if(hideCard(nft.tokenId)) setNode1(nft);; 
    } else if (!node2) {
      if(hideCard(nft.tokenId)) setNode2(nft);; 
    } else if (!node3) {
      if(hideCard(nft.tokenId)) setNode3(nft);; 
    } else if (!node4) {
      if(hideCard(nft.tokenId)) setNode4(nft);; 
    }
  };

  useEffect(() => {
    if (!provider  || parseInt(networkId) != 137) return;
    fetchOwnedNFTs();
  }, [provider, networkId]);


  const handleSwitchNetwork = async () => {
    await switchNetwork(137);
   
};

if (networkId != "137" && !SETTINGS.IS_TEST)  {
    return (
        <div className=" text-center">
            <div className="network-switch-section" style={{marginTop:"150px"}}>
             
                  <p>Switch to Polygon network:</p>
                  <ConnectButton />
                </div>
        </div>
    );
}

  return (
    <div className="page-container">
            <h3 className='liqnodes-header'>
            Crafting
            </h3>
            <p className="hero-p">Use the Craft Book as your guide to upgrading Liquidity Nodes and turning Power Cores into valuable rewards.</p>
      
      <Row className='mt-3'>
        <Col xs={6} md={3}>
        <CraftBook />
        </Col>
        <Col xs={6} md={3}>
        <Button variant="primary" className='buton-secondary primary buton btn' onClick={openNodesList}>
                Portfolio
              </Button>
        </Col>
        <Col xs={12} md={6}>
        </Col>
        <Col xs={12} sm={12} md={10} lg={8} xl={6} className="offset-xl-0 offset-lg-2 offset-md-1 offset-0 mt-4">
          <MergeContainer
            provider={provider}
            address={address}
            ownedNFTs={ownedNFTs}
            fetchOwnedNFTs={fetchOwnedNFTs}
            hideCard={hideCard}
            node1={node1}
            node2={node2}
            node3={node3}
            node4={node4}
            setNode1={setNode1}
            setNode2={setNode2}
            setNode3={setNode3}
            setNode4={setNode4}
          />
        </Col>
        <Col xs={12} sm={12} md={10} lg={8} xl={6} className="mb-4 offset-xl-0 offset-lg-2 offset-md-1 offset-0 mt-4">
          {loading ? (
            <center>
              <Spinner animation="border" role="status" className='loaderBig mt-5' />
            </center>
          ) : (
            <>
              <Row>
                <Col xs={12}>
                  <Alert variant="primary">
                    <div className='fw-bold'>
                      <small>Press and hold to drag or click to add element to the first empty slot.</small>
                    </div>
                  </Alert>
                </Col>
              </Row>
              {/* First Row - Manually selected token IDs */}
              <Row>
              <p className='craft-header'> My Liquidity Nodes:</p>
                {ownedNFTs.map((nft, index) => (
                  <Col xs={4} sm={4} md={3} lg={4} xl={3} key={index} style={{ display: (nft.tokenType <=6 && nft.visible) ? "" : "none" }}>
                    <div className="mb-3">

                      <DraggableNode
                        isNode={true}
                        key={index}
                        nft={nft}
                        onClick={handleNodeClick}
                      />
                    </div>
                  </Col>
                ))}
              </Row>

              {/* Second Row - Manually selected token IDs */}
              <Row>
              <p className='craft-header mt-3'> My Power Cores:</p>
                {distinctOwnedNFTs.map((nft, index) => (
                  <Col xs={4} sm={4} md={3} lg={4} xl={3} key={index} style={{ display: nft.tokenType > 6 ? "" : "none" }}>
                      <DraggableCore
                      isNode={false}
                        key={index}
                        nft={nft}
                        onClick={handleNodeClick} 
                      />
                      <div className='craftNumber' style={{ fontSize: '14px' }}>x {ownedNftTypeCount[nft.tokenType]}</div>
                  </Col>
                ))}
              </Row>

             
            </>
          )}
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </div>
  );
};

export default NodeMergeSection;