import React, { useEffect, useState } from 'react';
import governanceImg from '../governance.png';
import logoToken from '../logoToken.svg';
import { useContract } from '../hooks/useContract';
import QtechStakingABI from '../abis/QtechStakingABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import CardNode from '../components/CardNode';
import { useNavigate } from 'react-router-dom';
import ConnectButton from './ConnectButton';

function NodesList({ provider, address, isConnected, networkId, switchNetwork }) {
  const { ownedNFTs, totalRewards, coreRewards, airdropPower, totalDailyRewards, distinctElements, NFTTypeCount, ownedQworlds } = useContract(provider);
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();
  const [userLevel, setUserLevel] = useState(1);
  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-1 * (4))}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };


  async function getBalance() {
    if (!isConnected)
      setLoadingBalance(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {

      await getUserLevel();
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoadingBalance(false);
  }
  const openBuyToken = () => {
    navigate('/token');
  }
  const openStakeToken = () => {
    navigate('/staking');
  }
  const openBuyNode = () => {
    navigate('/qvrs-worlds');
  }
  const openFAQs = () => {
    navigate('/FAQs');
  }


  useEffect(() => {
    if (isConnected && parseInt(networkId) == 137) {
      getBalance();
    } else {
      setQvrsStakes([]);
      setTokenBalance("0.00");
      setUserLevel(1);
    }
  }, [isConnected, provider, networkId]);


  const handleSwitchNetwork = async () => {
    await switchNetwork(137);
   
};

if (networkId != "137" && !SETTINGS.IS_TEST)  {
    return (
        <div className=" text-center">
            <div className="network-switch-section" style={{marginTop:"150px"}}>
             
                  <p>Switch to Polygon network:</p>
                  <ConnectButton />
                </div>
        </div>
    );
}

  if (loading) {
    return (
      <div className=" text-center">
        <br />
        <br />
        <Spinner animation="border" role="status" className='loaderBig' />
        <p className='loaderMsg'>{txMessage}</p>
      </div>
    );
  }

  return (
    <>
      {/* Title */}
      <h2 className='liqnodes-header'>Portfolio</h2>
      <p className="hero-p">Manage your assets and claim daily rewards in your portfolio, showcasing all your Power Cores and Liquidity Nodes.</p> 
      {/* My info */}
      <Row className="mt-md-5 mt-2 mb-4 justify-content-center">
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center" >
          <h6 className='fw-normal mb-0 pt-2'>
            <small>Generating daily:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
            {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalDailyRewards)} {SETTINGS.tokenSymbol}
          </h6>
        </Col>
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center px-5 mx-1" >
          <h6 className='fw-normal mb-0 pt-2'>
            <small>Unclaimed:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
            {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalRewards)} {SETTINGS.tokenSymbol}
          </h6>
        </Col>
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center" >
          <h6 className='fw-normal mb-0 pt-2'>
            <small>WAVE Balance:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
            {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(usdtBalance)} WAVE
          </h6>
        </Col>
      </Row>

      <hr />

      {/* coreRewards */}
      <h3 className='pageTitle page-12 text-center mb-4 mt-4 pt-md-3 pt-0 pb-md-2 pb-0'>
            Starter Power Core Package
            </h3>
            <Row className="mt-0 mb-md-4 mb-0 justify-content-md-center justify-content-start">
            {Object.keys(coreRewards).map((key) => (
                <Col 
                xs={4} // Take 4 columns on on extra-small devices (portrait phones)
                sm={3} // Take 3 columns on small devices (landscape phones)
                md={2} // Take 2 columns on medium devices (tablets)
                lg={1} // Take 1 columns on large devices (desktops)
                className="mb-3" 
                key={key} 
                style={{ padding: "0 8px" }}
                >
                <h6 className='mb-md-0 mb-3 market-header' style={{ textAlign: "center" }}>
                    {SETTINGS.NODE_INFO[key].title}
                </h6>
                <img 
                    variant="top" 
                    src={SETTINGS.NODE_INFO[key].imgUrl} 
                    style={{ width: "100%", borderRadius: "10px" }} 
                    alt=""
                />
                <div className='craftNumber' style={{ fontSize: '14px' }}>x {coreRewards[key]}</div>
                </Col>
            ))}
            
         
            </Row>
      <hr />

      {/* NODES */}
      <h4 className='text-center mb-4 mt-4 pt-md-3 pt-0 pb-md-2 pb-0'>MY LIQUIDITY NODES</h4>
      <Row className="mt-0 mb-md-4 mb-0 justify-content-md-center justify-content-start">
        {ownedQworlds.map((nft, index) => (
          <Col xs={6} sm="auto" className="mb-3" key={index} style={{ padding: "0 8px" }}>
            <CardNode
              nft={nft}
              provider={provider}
              address={address}
              getBalanceFunction={getBalance}
              amount={NFTTypeCount[nft.tokenType]}
            />
          </Col>
        ))}
      </Row>

      <hr />

      {/* Exstras */}
      <h4 className='text-center mb-4 mt-4 pt-md-3 pt-0 pb-md-2 pb-0'>MY POWER CORES</h4>
      <Row className="mt-0 mb-4 justify-content-md-center justify-content-start">
        {distinctElements.map((nft, index) => (
          <Col xs={6} sm="auto" className="mb-3" key={index} style={{ padding: "0 8px" }}>
            <CardNode
              nft={nft}
              provider={provider}
              address={address}
              getBalanceFunction={getBalance}
              amount={NFTTypeCount[nft.tokenType]}
            />
          </Col>
        ))}
      </Row>

    </>
  );
}

export default NodesList;
