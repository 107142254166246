import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import PROFIT_CENTRE from "../PROFIT_CENTRE"; // Ensure the path to SETTINGS is correct
import SUPPLYS from "../supplys"; // Ensure the path to SETTINGS is correct

const ProfitCentre = () => {
  const [tokenData, setTokenData] = useState({});
  const [nativeData, setNativeData] = useState({});
  const [loading, setLoading] = useState(true);
  const etherScan = "https://tokens.waveswaps.com/images/chains/etherscan.svg";
  const smartChain = "https://tokens.waveswaps.com/images/chains/bsc.svg";
  const polyScan = "https://tokens.waveswaps.com/images/chains/polygonscan.svg";
  const arbiScan = "https://tokens.waveswaps.com/images/chains/arbiscan.svg";
  const optiScan = "https://tokens.waveswaps.com/images/chains/optimism.svg";
  const nativeBNB = "https://tokens.waveswaps.com/images/tokens/bnb.svg";
  const nativeETH = "https://tokens.waveswaps.com/images/tokens/ethereum.svg";
  const nativePOL = "https://tokens.waveswaps.com/images/tokens/polygon.svg";
  const feeUSDC = "https://tokens.waveswaps.com/images/tokens/usdc.svg";
  const feeUSDT = "https://tokens.waveswaps.com/images/tokens/usdt.svg";
  const feeGBL = "https://tokens.waveswaps.com/images/tokens/gbl.svg";

  // Utility to get the API URL for different networks
  const getApiUrlForNetwork = (network, tokenAddress, walletAddress, apiKey) => {
    let url;
    switch (network) {
      case "Polygon Mainnet":
        url = `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
        break;
      case "BNB Smart Chain":
        url = `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
        break;
      case "Ethereum Mainnet":
        url = `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
        break;
      case "Arbitrum One":
        url = `https://api.arbiscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
        break;
      case "OP Mainnet":
        url = `https://api-optimistic.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
        break;
      default:
        console.error("Unsupported network:", network);
        return null;
    }
    console.log(`Generated API URL for ${network}: ${url}`);
    return url;
  };
  
  // Updated fetchBalance with enhanced error handling and 6-decimal formatting
  const fetchBalance = async (asset, walletKey) => {
    const { tokenAddress, tokenDecimals, apiKey, network } = asset;
    const walletAddress = asset[walletKey];
  
    if (!tokenAddress || tokenAddress === "0x" || !walletAddress || walletAddress === "0x") {
      return { balanceAmount: 0, formattedBalanceAmount: "0.000000" };
    }
  
    const apiUrl = getApiUrlForNetwork(network, tokenAddress, walletAddress, apiKey);
    if (!apiUrl) return { balanceAmount: 0, formattedBalanceAmount: "0.000000" };
  
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        console.error(`Failed to fetch from ${apiUrl}:`, response.statusText);
        return { balanceAmount: 0, formattedBalanceAmount: "0.000000" };
      }
      const data = await response.json();
  
      if (data.status !== "1" || !data.result) {
        console.error(`Error fetching ${walletKey} amount:`, data.message || "Invalid data");
        return { balanceAmount: 0, formattedBalanceAmount: "0.000000" };
      }
  
      const balanceAmount = data.result / Math.pow(10, tokenDecimals);
      const formattedBalanceAmount = balanceAmount.toFixed(6); // Set to 6 decimals
      return { balanceAmount, formattedBalanceAmount };
    } catch (error) {
      console.error(`Error fetching ${walletKey} amount for ${walletKey}`, error);
      return { balanceAmount: 0, formattedBalanceAmount: "0.000000" };
    }
  };
  
  // Fetch AllData with 6-decimal formatting
  const fetchAllData = async () => {
    setLoading(true);
    const results = {};
  
    const hardCodedMonths = ["Oct_10", "Sept_09", "Aug_08", "Jul_07"];
  
    for (const [monthName, tokenSettings] of Object.entries(PROFIT_CENTRE.Centres || {})) {
      results[monthName] = {};
  
      const formatBalance = (balance) => parseFloat(balance).toFixed(6);
  
      // BNB Smart Chain
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.bsc.hardCodedValues) {
        results[monthName]["Smartchain USDT Fee"] = formatBalance(tokenSettings.networks.bsc.hardCodedValues.usdtFeeBalance);
        results[monthName]["Smartchain DEX Liquidity"] = formatBalance(tokenSettings.networks.bsc.hardCodedValues.dexWalletBalance);
      } else {
        const bscUsdtFee = await fetchBalance(tokenSettings.networks.bsc, "usdtFee");
        const bscDEX = await fetchBalance(tokenSettings.networks.bsc, "dexWallet");
  
        results[monthName]["Smartchain USDT Fee"] = formatBalance(bscUsdtFee.formattedBalanceAmount);
        results[monthName]["Smartchain DEX Liquidity"] = formatBalance(bscDEX.formattedBalanceAmount);
      }
  
      // Polygon
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.polygon.hardCodedValues) {
        results[monthName]["Polygon USDT Fee"] = formatBalance(tokenSettings.networks.polygon.hardCodedValues.usdtFeeBalance);
        results[monthName]["Polygon DEX Liquidity"] = formatBalance(tokenSettings.networks.polygon.hardCodedValues.dexWalletBalance);
        results[monthName]["Polygon Price Prediction Game"] = formatBalance(tokenSettings.networks.polygon.hardCodedValues.pricePredictionGameBalance);
      } else {
        const polygonUsdtFee = await fetchBalance(tokenSettings.networks.polygon, "usdtFee");
        const polygonDEX = await fetchBalance(tokenSettings.networks.polygon, "dexWallet");
        const polygonPricePredictionGame = await fetchBalance(tokenSettings.networks.polygon, "pricePredictionGame");
  
        results[monthName]["Polygon USDT Fee"] = formatBalance(polygonUsdtFee.formattedBalanceAmount);
        results[monthName]["Polygon DEX Liquidity"] = formatBalance(polygonDEX.formattedBalanceAmount);
        results[monthName]["Polygon Price Prediction Game"] = formatBalance(polygonPricePredictionGame.formattedBalanceAmount);
      }
  
      // Arbitrum
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.arbitrum.hardCodedValues) {
        results[monthName]["Arbitrum USDT Fee"] = formatBalance(tokenSettings.networks.arbitrum.hardCodedValues.usdtFeeBalance);
        results[monthName]["Arbitrum DEX Liquidity"] = formatBalance(tokenSettings.networks.arbitrum.hardCodedValues.dexWalletBalance);
      } else {
        const arbitrmUsdtFee = await fetchBalance(tokenSettings.networks.arbitrum, "usdtFee");
        const arbitrumDEX = await fetchBalance(tokenSettings.networks.arbitrum, "dexWallet");
  
        results[monthName]["Arbitrum USDT Fee"] = formatBalance(arbitrmUsdtFee.formattedBalanceAmount);
        results[monthName]["Arbitrum DEX Liquidity"] = formatBalance(arbitrumDEX.formattedBalanceAmount);
      }
  
      // Optimism
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.optimism.hardCodedValues) {
        results[monthName]["Optimism USDT Fee"] = formatBalance(tokenSettings.networks.optimism.hardCodedValues.usdtFeeBalance);
        results[monthName]["Optimism DEX Liquidity"] = formatBalance(tokenSettings.networks.optimism.hardCodedValues.dexWalletBalance);
      } else {
        const optimismUsdtFee = await fetchBalance(tokenSettings.networks.optimism, "usdtFee");
        const optimismDEX = await fetchBalance(tokenSettings.networks.optimism, "dexWallet");
  
        results[monthName]["Optimism USDT Fee"] = formatBalance(optimismUsdtFee.formattedBalanceAmount);
        results[monthName]["Optimism DEX Liquidity"] = formatBalance(optimismDEX.formattedBalanceAmount);
      }
  
      // Ethereum
      if (hardCodedMonths.includes(monthName) && tokenSettings.networks.ethereum.hardCodedValues) {
        results[monthName]["Ethereum DEX Liquidity"] = formatBalance(tokenSettings.networks.ethereum.hardCodedValues.dexWalletBalance);
        results[monthName]["Ethereum USDT Fee"] = formatBalance(tokenSettings.networks.ethereum.hardCodedValues.usdtFeeBalance);
      } else {
        const ethereumDEX = await fetchBalance(tokenSettings.networks.ethereum, "dexWallet");
        const ethereumUsdtFee = await fetchBalance(tokenSettings.networks.ethereum, "usdtFee");
  
        results[monthName]["Ethereum DEX Liquidity"] = formatBalance(ethereumDEX.formattedBalanceAmount);
        results[monthName]["Ethereum USDT Fee"] = formatBalance(ethereumUsdtFee.formattedBalanceAmount);
      }
  
      console.log(`Results for ${monthName}:`, results[monthName]);
    }
  
    setTokenData(results);
    setLoading(false);
  };
  
  useEffect(() => {
    fetchAllData();
  }, []);
  

  // Utility to get the API URL for fetching native currency balances
  const getApiUrlForNativeNetwork = (network, walletAddress, apiKey) => {
    switch (network) {
      case "Polygon Mainnet":
        return `https://api.polygonscan.com/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "BNB Smart Chain":
        return `https://api.bscscan.com/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "Ethereum Mainnet":
        return `https://api.etherscan.io/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "Arbitrum One":
        return `https://api.arbiscan.io/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case "OP Mainnet":
        return `https://api-optimistic.etherscan.io/api?module=account&action=balance&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error("Unsupported network:", network);
        return null;
    }
  };

  const fetchNativeBalance = async (networkSettings, walletKey) => {
    const { apiKey, network, nativeCurrencySymbol } = networkSettings;
    const walletAddress = networkSettings[walletKey];

    if (!walletAddress || walletAddress === "0x") {
      return { balanceAmount: 0, formattedBalanceAmount: "0" };
    }

    const apiUrl = getApiUrlForNativeNetwork(network, walletAddress, apiKey);
    if (!apiUrl) return { balanceAmount: 0, formattedBalanceAmount: "0" };

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status !== "1" || !data.result) {
        console.error(
          `Error fetching Native Fee for ${walletKey}`,
          data.message || "Invalid data"
        );
        return { balanceAmount: 0, formattedBalanceAmount: "0" };
      }

      const balanceAmount = data.result / Math.pow(10, 18);
      const formattedBalanceAmount =
        balanceAmount.toFixed(6) + ` ${nativeCurrencySymbol}`; // Display balance with 6 decimals and symbol

      return { balanceAmount, formattedBalanceAmount };
    } catch (error) {
      console.error(`Error fetching Native Fee for ${walletKey}`, error);
      return { balanceAmount: 0, formattedBalanceAmount: "0" };
    }
  };

  const fetchAllNativeData = async () => {
    setLoading(true);
    const results = {};

    // Define an array of months with hard-coded values
    const hardCodedMonths = ["October", "September", "August", "July"];

    for (const [monthName, tokenSettings] of Object.entries(
      PROFIT_CENTRE.Centres || {}
    )) {
      results[monthName] = {};
  
    // Helper function to format balance to 6 decimals
    const formatBalance = (balance) => parseFloat(balance).toFixed(6);


      // BNB Smartchain Native Fee
      if (
        hardCodedMonths.includes(monthName) &&
        tokenSettings.networks.bsc.hardCodedValues
      ) {
        results[monthName]["Smartchain Native Fee"] =
          tokenSettings.networks.bsc.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.bsc.tokenAddress !== "0x") {
        const bscNative = await fetchNativeBalance(
          tokenSettings.networks.bsc,
          "nativeFEE"
        );
        results[monthName]["Smartchain Native Fee"] = parseFloat(
          bscNative.formattedBalanceAmount
        ).toFixed(6);
      }

      // Polygon Price Prediction Game
      if (
        hardCodedMonths.includes(monthName) &&
        tokenSettings.networks.polygon.hardCodedValues
      ) {
        results[monthName]["Polygon Price Prediction Game"] =
        tokenSettings.networks.polygon.hardCodedValues.pricePredictionGame;
        results[monthName]["Polygon DAO Voting"] =
        tokenSettings.networks.polygon.hardCodedValues.daoVoting;
        results[monthName]["Polygon Native Fee"] =
        tokenSettings.networks.polygon.hardCodedValues.nativeFEE

      } else if (tokenSettings.networks.polygon.tokenAddress !== "0x") {
        const polygonPricePredictionGame = await fetchNativeBalance(
          tokenSettings.networks.polygon,
          "pricePredictionGame"
        );
        const polygonDao = await fetchNativeBalance(
          tokenSettings.networks.polygon,
          "daoVoting"
        );
        const polygonNative = await fetchNativeBalance(
          tokenSettings.networks.polygon,
          "nativeFEE"
        );

        results[monthName]["Polygon Price Prediction Game"] = parseFloat(
          polygonPricePredictionGame.formattedBalanceAmount
        ).toFixed(6);
        results[monthName]["Polygon DAO Voting"] = parseFloat(
          polygonDao.formattedBalanceAmount
        ).toFixed(6);
        results[monthName]["Polygon Native Fee"] = parseFloat(
          polygonNative.formattedBalanceAmount
        ).toFixed(6);
      }

      // Arbitrum Native Fee
      if (
        hardCodedMonths.includes(monthName) &&
        tokenSettings.networks.arbitrum.hardCodedValues
      ) {
        results[monthName]["Arbitrum Native Fee"] =
          tokenSettings.networks.arbitrum.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.arbitrum.tokenAddress !== "0x") {
        const arbitrumNative = await fetchNativeBalance(
          tokenSettings.networks.arbitrum,
          "nativeFEE"
        );
        results[monthName]["Arbitrum Native Fee"] = parseFloat(
          arbitrumNative.formattedBalanceAmount
        ).toFixed(6);
      }

      // Optimism Native Fee
      if (
        hardCodedMonths.includes(monthName) &&
        tokenSettings.networks.optimism.hardCodedValues
      ) {
        results[monthName]["Optimism Native Fee"] =
          tokenSettings.networks.optimism.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.optimism.tokenAddress !== "0x") {
        const optimismNative = await fetchNativeBalance(
          tokenSettings.networks.optimism,
          "nativeFEE"
        );
        results[monthName]["Optimism Native Fee"] = parseFloat(
          optimismNative.formattedBalanceAmount
        ).toFixed(6);
      }
      // Ethereum Native Fee
      if (
        hardCodedMonths.includes(monthName) &&
        tokenSettings.networks.ethereum.hardCodedValues
      ) {
        results[monthName]["Ethereum Native Fee"] =
          tokenSettings.networks.ethereum.hardCodedValues.nativeFEE;
      } else if (tokenSettings.networks.ethereum.tokenAddress !== "0x") {
        const ethereumNative = await fetchNativeBalance(
          tokenSettings.networks.ethereum,
          "nativeFEE"
        );
        results[monthName]["Ethereum Native Fee"] = parseFloat(
          ethereumNative.formattedBalanceAmount
        ).toFixed(6);
      }
    }

    setNativeData(results);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllNativeData();
  }, []);

  // Helper function to render network and token logos
  const renderNetworkTokenLogos = (network, monthName) => {
    const tokenSettings = PROFIT_CENTRE.Centres[monthName]?.networks[network];

    if (!tokenSettings || tokenSettings.tokenAddress === "0x") {
      return null; // Handle cases where the token is not deployed on this network
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          href={tokenSettings.networkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={tokenSettings.networkLogo}
            alt="Network Logo"
            style={{ width: "18px", height: "auto", marginRight: "20px" }}
          />
        </a>
        <a
          href={tokenSettings.tokenUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={tokenSettings.tokenLogo}
            alt="Token Logo"
            style={{ width: "18px", height: "auto" }}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="supplies-container mt-3 ml-1 mctable">
      <h2 className="pageTitle supplies-header">Profit Centres</h2>
      <p className="hero-p sub-header">
        Empowering DeFi with Transparent Shareholding and Current Calculation Cycle Revenue
        Distribution.
      </p>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <button
          onClick={fetchAllData}
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            cursor: "pointer",
            marginRight: "10px",
          }}
          className="refresh-button hero-button "
          disabled={loading}
        >
          {loading ? "Loading..." : "Refresh Data"}
        </button>
      </div>
      <div className="table-responsive mb-3" style={{ overflowX: "auto" }}>
  <Table
    className="w-100 table-bordered-custom"
    style={{ borderCollapse: "collapse", tableLayout: "fixed", width: "auto" }}
  >
    <colgroup>
      <col style={{ width: "180px" }} />
      {Object.keys(PROFIT_CENTRE.Centres || {}).map(() => (
        <col style={{ width: "130px" }} />
      ))}
      <col style={{ width: "300px" }} /> {/* Fixed width for the description column */}
    </colgroup>
    <thead>
      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
        <th
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            fontWeight: "bold",
            fontFamily: "Rubik, sans-serif",
            fontSize: "14px",
          }}
        >
          Profit Centre
        </th>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <th
            style={{
              textAlign: "center",
              verticalAlign: "middle",
              fontWeight: "bold",
              fontFamily: "Rubik, sans-serif",
              fontSize: "14px",
            }}
            key={idx}
          >
            {PROFIT_CENTRE.Centres[monthName].monthName}
          </th>
        ))}
        <th
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            fontWeight: "bold",
            fontFamily: "Rubik, sans-serif",
            fontSize: "14px",
          }}
        >
          Description
        </th>
      </tr>
    </thead>
    <tbody>
      {/* Empty row without left and right borders */}
      <tr className="no-border">
        <td
          colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 2}
          style={{
            borderLeft: "none",
            borderRight: "none",
            height: "10px",
          }}
        ></td>
      </tr>

      {/* Data rows */}
      <tr>
        <td
          style={{
            textAlign: "left",
            fontWeight: "bold",
            verticalAlign: "middle",
            fontFamily: "Ubuntu, sans-serif",
            fontSize: "14px",
            color: "#555",
            paddingLeft: "10px",
          }}
        >
          <img
            src={smartChain}
            alt="SmartChain"
            style={{
              width: "18px",
              height: "auto",
              marginRight: "10px",
              paddingBottom: "2px",
            }}
          />
          BNB Smart Chain
        </td>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <td
            key={idx}
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            {/* Add dynamic data */}
          </td>
        ))}
        <td
          style={{
            textAlign: "left",
            verticalAlign: "middle",
            fontFamily: "Rubik, sans-serif",
            fontSize: "13px",
            paddingLeft: "10px",
          }}
        >
          This column provides additional details about the profit centers for BNB Smart Chain.
        </td>
      </tr>

      {/* Additional rows */}
      <tr>
        <td
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            fontWeight: "normal",
            fontFamily: "Rubik, sans-serif",
            fontStyle: "italic",
            fontSize: "13px",
            color: "#555",
            paddingRight: "10px",
          }}
        >
          <a
            href="https://bscscan.com/address/0x07dDF9A4230A0324317e062F9ccD666E26887Db2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Transaction Fee in BNB
          </a>
        </td>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <td
            key={idx}
            style={{
              textAlign: "right",
              verticalAlign: "middle",
              fontFamily: "Rubik, sans-serif",
              fontStyle: "italic",
              fontSize: "13px",
              color: "#555",
              paddingRight: "10px",
            }}
          >
            {loading
              ? "Loading..."
              : nativeData[monthName]?.["Smartchain Native Fee"] || "N/A"}
            <img
              src={nativeBNB}
              alt=""
              style={{
                width: "17px",
                height: "auto",
                marginLeft: "10px",
                paddingBottom: "2px",
              }}
            />
          </td>
        ))}
        <td
          style={{
            textAlign: "left",
            verticalAlign: "middle",
            fontFamily: "Rubik, sans-serif",
            fontSize: "13px",
            paddingLeft: "10px",
          }}
        >
          Reflects the transaction fee for operations conducted in BNB on the BNB Smart Chain.
        </td>
      </tr>

            {/* Smartchain V1 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x5578fDCb9212B3B829dd3986C72e745FDACE3BBc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in USDT
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Smartchain USDT Fee"]
                      ? tokenData[monthName]["Smartchain USDT Fee"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in USDT on the BNB Smart Chain.
                  </td>
                </tr>

            {/* Smartchain DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://bscscan.com/address/0x86D3dA36A85E08741E3D33cdB886E64242B55d3a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Smartchain DEX Liquidity"]
                      ? tokenData[monthName]["Smartchain DEX Liquidity"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee collected from liquidity operations like swaps and pool management on DEX.
                  </td>
                </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Polygon Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",

                  paddingLeft: "10px",
                }}
              >
                <img
                  src={polyScan}
                  alt="PolygonScan"
                  style={{
                    width: "18px",
                    height: "auto",
                    marginRight: "10px",
                    paddingBottom: "2px",
                  }}
                />
                Polygon Mainnet
                </td>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <td
            key={idx}
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            {/* Add dynamic data */}
          </td>
        ))}
        <td
          style={{
            textAlign: "left",
            verticalAlign: "middle",
            fontFamily: "Rubik, sans-serif",
            fontSize: "13px",
            paddingLeft: "10px",
          }}
        >
          This column provides additional details about the profit centers for Polygon Mainnet.
        </td>
      </tr>

            {/* Polygon Price Prediction Game row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xF96f56b05Fbeee588B28f3Dc999b63ddba2273D3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Price Prediction Game Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : nativeData[monthName]?.["Polygon Price Prediction Game"] || "N/A"}
                    <img
                      src={nativePOL}
                      alt=""
                      ClassName=""
                      style={{
                        width: "17px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee earned from user participation in the Price Prediction Game.
                  </td>
                </tr>

            {/* Polygon DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xF96f56b05Fbeee588B28f3Dc999b63ddba2273D3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Price Prediction Game USDT Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Polygon Price Prediction Game"]
                      ? tokenData[monthName]["Polygon Price Prediction Game"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Income from user subscriptions in the Price Prediction Game.
                  </td>
                </tr>

            {/* Polygon Voting row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x2BF314D7e43639cE65c9D8faAa61c77C1557952e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DAO Voting Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : nativeData[monthName]?.["Polygon DAO Voting"] || "N/A"}
                    <img
                      src={nativePOL}
                      alt=""
                      ClassName=""
                      style={{
                        width: "17px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee collected from users for submitting proposals and casting votes in DAO governance.
                  </td>
                </tr>

            {/* Polygon Native Currency FEE with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in POL
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : nativeData[monthName]?.["Polygon Native Fee"] || "N/A"}
                    <img
                      src={nativePOL}
                      alt=""
                      ClassName=""
                      style={{
                        width: "17px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in POL on the Polygon Mainnet.
                  </td>
                </tr>

            {/* Polygon V1 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0xe7b8583D3A1166E1Bb0d8BB04E65fAB9FaA3E217"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in USDT
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Polygon USDT Fee"]
                      ? tokenData[monthName]["Polygon USDT Fee"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in USDT on the Polygon Mainnet.
                  </td>
                </tr>

            {/* Polygon DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://polygonscan.com/address/0x131439c89353A3379913482A21d1F7e420313Cbd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity USDT Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Polygon DEX Liquidity"]
                      ? tokenData[monthName]["Polygon DEX Liquidity"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee collected from liquidity operations like swaps and pool management on DEX.
                  </td>
                </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Arbitrum Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                  paddingLeft: "10px",
                }}
              >
                <img
                  src={arbiScan}
                  alt="Arbitrum One"
                  style={{
                    width: "18px",
                    height: "auto",
                    marginRight: "10px",
                    paddingBottom: "2px",
                  }}
                />
                Arbitrum One
                </td>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <td
            key={idx}
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            {/* Add dynamic data */}
          </td>
        ))}
        <td
          style={{
            textAlign: "left",
            verticalAlign: "middle",
            fontFamily: "Rubik, sans-serif",
            fontSize: "13px",
            paddingLeft: "10px",
          }}
        >
          This column provides additional details about the profit centers for Arbitrum One.
        </td>
      </tr>

            {/* Arbitrum Native Currency FEE row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in ETH
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : nativeData[monthName]?.["Arbitrum Native Fee"] || "N/A"}
                    <img
                      src={nativeETH}
                      alt=""
                      ClassName=""
                      style={{
                        width: "17px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in ETH on the Arbitrum One.
                  </td>
                </tr>

            {/* Arbitrum V3 GBL Bot row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in USDT
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Arbitrum USDT Fee"]
                      ? tokenData[monthName]["Arbitrum USDT Fee"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      lassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in ETH on the Arbitrum One.
                  </td>
                </tr>

            {/* Arbitrum DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://arbiscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Arbitrum DEX Liquidity"]
                      ? tokenData[monthName]["Arbitrum DEX Liquidity"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee collected from liquidity operations like swaps and pool management on DEX.
                  </td>
                </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Optimism Mainnet row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                  paddingLeft: "10px",
                }}
              >
                <img
                  src={optiScan}
                  alt="OP Mainnet"
                  style={{
                    width: "18px",
                    height: "auto",
                    marginRight: "10px",
                    paddingBottom: "2px",
                  }}
                />
                OP Mainnet
                </td>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <td
            key={idx}
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            {/* Add dynamic data */}
          </td>
        ))}
        <td
          style={{
            textAlign: "left",
            verticalAlign: "middle",
            fontFamily: "Rubik, sans-serif",
            fontSize: "13px",
            paddingLeft: "10px",
          }}
        >
          This column provides additional details about the profit centers for OP Mainnet.
        </td>
      </tr>

            {/* Optimism Native Currency FEE row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in ETH
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : nativeData[monthName]?.["Optimism Native Fee"] || "N/A"}
                    <img
                      src={nativeETH}
                      alt=""
                      ClassName=""
                      style={{
                        width: "17px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in ETH on the OP Mainnet.
                  </td>
                </tr>

            {/* Optimism Cross Chain Swap row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in USDT
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Optimism USDT Fee"]
                      ? tokenData[monthName]["Optimism USDT Fee"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      lassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in USDT on the OP Mainnet.
                  </td>
                </tr>

            {/* Optimism DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://optimistic.etherscan.io/address/0x233Ec5995080CFc861B4a71EEe7766b555bDC22e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Optimism DEX Liquidity"]
                      ? tokenData[monthName]["Optimism DEX Liquidity"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee collected from liquidity operations like swaps and pool management on DEX.
                  </td>
                </tr>

            {/* Empty row without left and right borders */}
            <tr className="no-border">
              <td
                colSpan={Object.keys(PROFIT_CENTRE.Centres || {}).length + 1}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  height: "10px",
                }}
              ></td>
            </tr>

            {/* Ethereum row with logos */}
            <tr>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  fontFamily: "Ubuntu, sans-serif",
                  fontSize: "14px",
                  color: "#555",
                  paddingLeft: "10px",
                }}
              >
                <img
                  src={etherScan}
                  alt="Etherscan"
                  style={{
                    width: "18px",
                    height: "auto",
                    marginRight: "10px",
                    paddingBottom: "2px",
                  }}
                />
                Ethereum Mainnet
                </td>
        {Object.keys(PROFIT_CENTRE.Centres || {}).map((monthName, idx) => (
          <td
            key={idx}
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            {/* Add dynamic data */}
          </td>
        ))}
        <td
          style={{
            textAlign: "left",
            verticalAlign: "middle",
            fontFamily: "Rubik, sans-serif",
            fontSize: "13px",
            paddingLeft: "10px",
          }}
        >
          This column provides additional details about the profit centers for Ethereum Mainnet.
        </td>
      </tr>

            {/* Ethereum Native Currency FEE row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Transaction Fee in ETH
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      fontStyle: "italic",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : nativeData[monthName]?.["Ethereum Native Fee"] || "N/A"}
                    <img
                      src={nativeETH}
                      alt=""
                      ClassName=""
                      style={{
                        width: "17px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Reflects the transaction fee for operations conducted in ETH on the Ethereum Mainnet.
                  </td>
                </tr>

            {/* Ethereum DEX Liquidity row with hyperlink */}
            <tr>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "middle",
                  fontWeight: "normal",
                  fontFamily: "Rubik, sans-serif",
                  fontStyle: "italic",
                  fontSize: "13px",
                  color: "#555",
                  paddingRight: "10px",
                }}
              >
                <a
                  href="https://etherscan.io/address"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEX Liquidity Fee
                </a>
              </td>
              {Object.keys(PROFIT_CENTRE.Centres || {}).map(
                (monthName, idx) => (
                  <td
                    key={idx}
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontStyle: "italic",
                      fontSize: "13px",
                      color: "#555",
                      paddingRight: "10px",
                    }}
                  >
                    {loading
                      ? "Loading..."
                      : tokenData[monthName] &&
                        tokenData[monthName]["Ethereum DEX Liquidity"]
                      ? tokenData[monthName]["Ethereum DEX Liquidity"]
                      : "N/A"}
                    <img
                      src={feeUSDT}
                      alt=""
                      ClassName=""
                      style={{
                        width: "16px",
                        height: "auto",
                        marginLeft: "10px",
                        paddingBottom: "2px",
                      }}
                      />
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "13px",
                      paddingLeft: "10px",
                    }}
                  >
                    Fee collected from liquidity operations like swaps and pool management on DEX.
                  </td>
                </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ProfitCentre;
