const MANAGEMENT = {
    ASSETS: {
      DAOToken: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/DAO.svg",
        chainExplorer: "https://polygonscan.com/token/0x8053563b0678a2Cd900BAbc8756cC55e25685215",
        name: "DAOToken",
        symbol: "DAO",
        walletAddress: 'OX',
        wallet: '0X',
        tokenAddress1: '0x8053563b0678a2Cd900BAbc8756cC55e25685215',
        tokenAddress2: '0X',
        tokenDecimals1: 0,  // Decimals for tokenAddress1
        tokenDecimals2: 0,   // Decimals for tokenAddress2
        totalSupply: 1000000,
        chart: "#",
        chainId: 137,  // Polygon Mainnet chain ID
        network: 'Polygon Mainnet',  // Name of the network
        rpcUrl: 'https://polygon-mainnet.infura.io',  // New RPC URL
        currencySymbol: 'POL',  // Currency symbol
        blockExplorerUrl: 'https://polygonscan.com/',  // Block explorer URL
        apiKey: 'A5RSY8TZWSSRFTQMRNWARDXECKM1Q1ZYYE', 
        },
    WaveShare: {
        assetLogo: "https://tokens.waveswaps.com/images/tokens/WSHARE.svg",
        chainExplorer: "https://bscscan.com/address/0x51c2b96740aa4591e84747522559b870e3f531c3",
        name: "WaveShare",
        symbol: "WSHARE",
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x51C2b96740aa4591E84747522559B870e3F531C3',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        totalSupply: 100,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x51c2b96740aa4591e84747522559b870e3f531c3/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    },
    SHAREHOLDERS: {
    DAO: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/tokenholdings?a=0x51c2b96740aa4591e84747522559b870e3f531c3",
        shareholder: "DAO Community",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x51C2b96740aa4591E84747522559B870e3F531C3',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 49,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x51c2b96740aa4591e84747522559b870e3f531c3/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_01: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-01",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x5050eD25de442A7bcFEc528499BC914DBbDC7AD5',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 10,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_02: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-02",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0xefdA4aBeF2c072b1e7caAc08Ab4970B134a2F7A9',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 10,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_03: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-03",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0xa4959413C46fa8c1E8A950e06bC901C132582360',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 5,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_04: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-04",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0xc2f9823fDceE8D013D43c6310CeED2DA59EcA4c8',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 5,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_05: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-05",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x8Ae23931a74920F83Dc5eE517Dcd39e05DB20396',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 5,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_06: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-06",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0xDEd06562EE8f1b82b29369a119F344343fB3E2E5',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 5,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_07: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-07",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x3D668F9280fB2b518b6562C822b741648A6BcF0C',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 5,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_08: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-08",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x7808E5bdE358D23aB1c551C859568968B4Aa9316',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 1,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_09: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-09",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0xD5672da0468930896DbD2804f9F26B321c3D30F6',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 1,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_10: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-10",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0xee5113C463d14f7c3EBfEA7496c9Ead0e19860fc',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 1,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_11: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-11",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x0b8C753d3a899a0f37afaF5F6B7490bE10750EA2',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 1,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
    DEX_12: {
        assetLogo: "https://tokens.waveswaps.com/images/pie.svg",
        chainExplorer: "https://bscscan.com/token/0x0d4aC846FAb59C7602dd2ed9B9ff9260413BeB6e?a=0xded6db2c6ac5fedfeb34bd589164ba149c3a8bd4",
        shareholder: "Liquidity-Provider-12",
        symbol: "WSHARE",
        shAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        walletAddress: '0x20d71739884807c779feb3e3e21231ae9d9ff45d',
        wallet: '0x2733990321Ff62C47d59c96c54F957e8d108D7C0',
        tokenAddress1: '0x0d4ac846fab59c7602dd2ed9b9ff9260413beb6e',
        tokenAddress2: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        tokenDecimals1: 18,  // Decimals for tokenAddress1
        tokenDecimals2: 18,   // Decimals for tokenAddress2
        percentage: 1,
        chart: "https://coinmarketcap.com/dexscan/bsc/0x20d71739884807c779feb3e3e21231ae9d9ff45d/",
        chainId: 56,  // SmartChain ID
        network: 'BNB Smart Chain',  // Name of the network
        rpcUrl: 'https://bsc-dataseed.binance.org/',  // New RPC URL
        currencySymbol: 'BNB',  // Currency symbol
        blockExplorerUrl: 'https://bscscan.com/',  // Block explorer URL
        apiKey: 'UQI5TVFD2AIX5D1NC7G4R268J93Q1ZZEIA',
        },
      
      // Add other assets similarly...
    },
}

export default MANAGEMENT;