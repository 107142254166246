const PROFIT_CENTRE = {
    Centres: {
        Period: {
            monthName: "Current Calculation Cycle",
            networks: {
                bsc: {
                    apiKey: "EC4BYGUX9CFQTZG4TRK5A2NW9ICEGW8TPU",
                    tokenAddress: "0x55d398326f99059ff775485246999027b3197955",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "BNB",
                    nativeFEE: "0x07dDF9A4230A0324317e062F9ccD666E26887Db2",
                    usdtFee: "0x07dDF9A4230A0324317e062F9ccD666E26887Db2",
                    dexWallet: "0x86D3dA36A85E08741E3D33cdB886E64242B55d3a",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/bsc.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/bnb.svg",  
                    networkUrl: "https://bscscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://bscscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'BNB Smart Chain'
                },
                polygon: {
                    apiKey: "N1BTTYAGTHBV991I6QYJQT1D3S3SWFXA4G",
                    tokenAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
                    tokenDecimals: 6,
                    nativeCurrencySymbol: "POL",
                    pricePredictionGame: "0xF96f56b05Fbeee588B28f3Dc999b63ddba2273D3",
                    daoVoting: "0x30D9224d3A2347B95A166706bf4F6242e4e9C6a5",
                    daoContractStandard: "0x30D9224d3A2347B95A166706bf4F6242e4e9C6a5",
                    daoContractWeighted: "0x30D9224d3A2347B95A166706bf4F6242e4e9C6a5",
                    nativeFEE: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    usdtFee: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    dexWallet: "0x131439c89353A3379913482A21d1F7e420313Cbd",
                    

                    networkLogo: "https://tokens.waveswaps.com/images/chains/polygonscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/pol.svg",  
                    networkUrl: "https://polygonscan.com/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://polygonscan.com/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Polygon Mainnet'
                },
                arbitrum: {
                    apiKey: "6V8T715FJ3A1M36G2I8DPKH4E7KV5421WP",
                    tokenAddress: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
                    tokenDecimals: 6,
                    nativeCurrencySymbol: "ETH",
                    nativeFEE: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    usdtFee: "0xd32bD8a06e011c3C0cb1445551dF5d2f53b581eE",
                    dexWallet: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/arbiscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://arbiscan.io/address/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",  
                    tokenUrl: "https://arbiscan.io/token/0xc14a7747cfec02cfea62e72bb93538de6b2078e6",
                    network: 'Arbitrum One'
                },
                optimism: {
                    apiKey: "DY9NCT2TM6CND67FY1ARTJ88UVPVGIAIM6",
                    tokenAddress: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
                    tokenDecimals: 6,
                    nativeFEE: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    usdtFee: "0x15E4a42e6dd5817BCD402C8e6A57b284F443fc3c",
                    dexWallet: "0x233Ec5995080CFc861B4a71EEe7766b555bDC22e",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/optimism.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://optimistic.etherscan.io/address/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",  
                    tokenUrl: "https://optimistic.etherscan.io/token/0x181D739A0C016Bd0B32bd6e47cbcA70D3A0e69d7",
                    network: 'OP Mainnet'
                },
                ethereum: {
                    apiKey: "56JIH8X9YRRCTI13KEYCKTAZP5IB5VF9HC",
                    tokenAddress: "0x000000000000000000000000000000000000",
                    tokenDecimals: 18,
                    nativeCurrencySymbol: "ETH",
                    nativeFEE: "0x000000000000000000000000000000000000",
                    usdtFee: "0x000000000000000000000000000000000000",
                    crossChainSwap: "0x000000000000000000000000000000000000",
                    v3GBLbot: "0x000000000000000000000000000000000000",
                    dexWallet: "0x000000000000000000000000000000000000",
                    pricePredictionGame: "0x000000000000000000000000000000000000",
                    liquidityPoolWallet: "0x000000000000000000000000000000000000",
                    ecosystemWallet: "0x000000000000000000000000000000000000",

                    networkLogo: "https://tokens.waveswaps.com/images/chains/etherscan.svg",  
                    tokenLogo: "https://tokens.waveswaps.com/images/tokens/eth.svg",  
                    networkUrl: "https://etherscan.io",  
                    tokenUrl: "https://etherscan.io",
                    network: 'Ethereum Mainnet'
                },
            }
          },
    },
  };
  
export default PROFIT_CENTRE;
  