import React, { useRef, useEffect } from 'react';

const MatrixEffect = ({ className = '', quantity = 30, speed = 50 }) => {
  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const context = useRef(null);
  const columns = useRef([]);
  const canvasSize = useRef({ w: 0, h: 0 });
  const dpr = typeof window !== 'undefined' ? window.devicePixelRatio : 1;

  const characters = '0123456789ABCDEF@#$%&*'; // Characters to use in the effect
  const fontSize = 16; // Font size for the falling characters
  const columnsCount = useRef(0); // Number of columns of characters

  useEffect(() => {
    if (canvasRef.current) {
      context.current = canvasRef.current.getContext('2d');
    }
    initCanvas();
    animate();
    window.addEventListener('resize', initCanvas);

    return () => {
      window.removeEventListener('resize', initCanvas);
    };
  }, []);

  const initCanvas = () => {
    resizeCanvas();
    columnsCount.current = Math.floor(canvasSize.current.w / fontSize);
    columns.current = Array(columnsCount.current).fill(0); // Initialize columns with starting y-position 0
  };

  const resizeCanvas = () => {
    if (canvasContainerRef.current && canvasRef.current && context.current) {
      canvasSize.current.w = canvasContainerRef.current.offsetWidth;
      canvasSize.current.h = canvasContainerRef.current.offsetHeight;
      canvasRef.current.width = canvasSize.current.w * dpr;
      canvasRef.current.height = canvasSize.current.h * dpr;
      canvasRef.current.style.width = canvasSize.current.w + 'px';
      canvasRef.current.style.height = canvasSize.current.h + 'px';
      context.current.scale(dpr, dpr);
      context.current.font = `${fontSize}px monospace`; // Set font style for characters
    }
  };

  const drawMatrix = () => {
    if (context.current) {
      // Add translucent background to simulate fading trail
      context.current.fillStyle = 'rgba(11, 65, 130, 0.05)';
      context.current.fillRect(0, 0, canvasSize.current.w, canvasSize.current.h);

      context.current.fillStyle = '#7DA7F0'; // Matrix blue color
      columns.current.forEach((y, index) => {
        const text = characters.charAt(Math.floor(Math.random() * characters.length)); // Pick a random character
        const x = index * fontSize; // Calculate x position based on column index

        // Draw character
        context.current.fillText(text, x, y * fontSize);

        // Reset the y position if it goes off-screen
        if (y * fontSize > canvasSize.current.h && Math.random() > 0.975) {
          columns.current[index] = 0;
        } else {
          columns.current[index] = y + 1; // Move the character down by 1 unit
        }
      });
    }
  };

  const animate = () => {
    drawMatrix();
    setTimeout(() => {
      window.requestAnimationFrame(animate);
    }, speed);
  };

  return (
    <div className={className} ref={canvasContainerRef} aria-hidden="true">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default MatrixEffect;
