import React, { useState, useEffect, useContext } from "react";

import {
    BrowserProvider,
    Contract,
    formatUnits,
    parseUnits,
    isAddress,
    parseEther,
    formatEther
  } from "ethers";

import tokenSwapABI from "../abis/TokenSwapABI";
import RecycleSwapABI from "../abis/WaweSwapsV1ABI.json";
import RecycleSwapStorageABI from "../abis/WaweSwapStorageV1ABI.json";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";

import styled from "@emotion/styled";


import {
    BiHome,
    BiCopy,
    BiArrowToBottom,
    BiCaretDown,
    BiCaretUp,
  } from "react-icons/bi";


import SETTINGS from "../SETTINGS";
import Dropdown from 'react-bootstrap/Dropdown';
import { ERC20_ABI } from "../abis/erc20";

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import InfoModal from './InfoModal';
import ToastNotification from './ToastNotification';
import LoadingDots from "./LoadingDots";

const ClaimButton = styled.div`
  display:inline-block !important;
  background-color: transparent;
  color: var(--maincolor);
  font-weight: bold;
  font-size: 10px;
  border: 1px solid var(--maincolor);
  padding: 5px 20px;
  border-radius:100px;
  cursor: pointer;
  margin-left:0px;
 margin-top:12px;
  transition: all 0.2s;
  text-align:center;
  &:hover {
    background-color:var(--maincolor);
    color:#FFFFFF;
    transition: all 0.6s;
  }
`;

const SpinnerBox = styled.div`
display:inline-block !important;
background-color: transparent;
color: var(--maincolor);
font-weight: bold;
font-size: 20px;
margin-left:50px;
  text-align:center;
`;

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const ACTIVE = 'accordionActive';
  const CLOSED = 'accordionClosed';
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={isCurrentEventKey ? ACTIVE : CLOSED }
      onClick={decoratedOnClick}
    >
     <BiCaretDown style={{fontSize:20}}  /> {children}
    </button>
  );
}
const SwapV1BNB = ({
    provider,
    selectedAccount,
    networkSymbol,
    isConnected,
    networkName,
    networkId,
    switchNetwork,
  }) => {

  const [stakes, setStakes] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [stakesLoaded, setStakesLoaded] = useState(false);
  const [swapAvailable, setSwapAvailable] = useState(false);
  const [gblBalance, setGblBalance] = useState('');
  const [gblAlowance, setGblAlowance] = useState('');
  const [recAlowance, setRecAlowance] = useState('0');
  const [swapTokenAmount, setSwapTokenAmount] = useState('');
  const [swapRewardAmount, setSwapRewardAmount] = useState('');
  const [recycleAmount, setRecycleAmount] = useState('');
  const [recBalance, setRecBalance] = useState(0);
  const [fee, setFee] = useState(0);
  const [numOfStakes, setNumOfStakes] = useState(1);
  const [claimInProgress, setClaimInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loadingText, setLoadingText] = useState("Loading");
    const [loadingStep, setLoadingStep] = useState(1);
    const [loadingNumber, setLoadingNumber] = useState(1);
  const [tokens, setTokens] = useState([]);
  const [selectedRecycleToken, setSelectedRecycleToken] = useState(null);
  const [selectedLabelRecycle, setSelectedLabelRecycle] = useState("Select");
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [unclaimedRewardsNet, setUnclaimedRewardsNet] = useState(0);
  const [unclaimedRewardsFee, setUnclaimedRewardsFee] = useState(0);

  const [showToast, setShowToast] = useState(false);
const [toastUrl, setToastUrl] = useState(null);
const [toastError, setToastError] = useState(false);
const [toastMessage, setToastMessage] = useState("-");
const [waveStakes, setWaveStakes] = useState([]);


const displayToast = (msg, url = null,  error = false) => {
  setToastMessage(msg);
  setToastUrl(url);
  setToastError(error);
  setShowToast(true);
};









 
  const handleSelectRecycle = async (token) => {
    
    setSelectedLabelRecycle(`${token.symbol}`);
    await functionRecycleTokenChange(token.address);
  };

  const functionRecycleTokenChange = async (addr) => {
    // Find and set the selected token
    const token = tokens.find((token) => token.address === addr);
    setSelectedRecycleToken(token);
    console.log(token);
  
    // Retrieve balances and price details
    const rBal = await getBalanceERC(token.address);
    setRecBalance(rBal);
  
    const priceGbl = await getPriceNetwork(SETTINGS.globalTokenAddress);
    const priceToken = await getPriceNetwork(token.address);
  
    // Perform calculation for recycle amount
    const recycleAM = 
      (parseFloat(swapTokenAmount) * parseFloat(formatEther(priceGbl))) /
      parseFloat(formatEther(priceToken)) / 100;
  
    setRecycleAmount(recycleAM.toFixed(4));
  
    // Create a contract instance for the selected token
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
   
  
    // Check if balance is sufficient
    if (parseFloat(recycleAM) > parseFloat(rBal)) {
      setErrorText("Balance too low!");
      setSwapAvailable(false);
    } else {
      setErrorText("");
      setSwapAvailable(true);
    }
  };
  

  const collectIncome = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
  
    try {
      const contract = new Contract(
        SETTINGS.recycleSwapAddress,
        RecycleSwapABI,
        signer
      );
  
      const tx = await contract.withdrawReward({
        gasLimit: 300000, // Adjust if necessary
        from: selectedAccount,
      });
  
      await tx.wait(); // Wait for the transaction to be confirmed
      displayToast('Rewards collected!', tx.hash);
    } catch (error) {
      console.error("An error occurred:", error);
      displayToast(error.message, null, true);
    }
  
    handleCloseCollectModal();
    setLoading(false);
  };
  
  const handleCloseCollectModal = () => {
    setShowCollectModal(false);
  };

  const handleShowCollectModal = () => {
    setShowCollectModal(true);
  };

  const getBalanceERC = async (addr) => {
    if (!isAddress(addr)) {
      // alert("Please enter a valid contract address.");
      return;
    }
  
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const tokenContract = new Contract(addr, ERC20_ABI, signer);
  
    try {
      const balance = await tokenContract.balanceOf(selectedAccount);
      const decimals = await tokenContract.decimals();
      
      const formattedBalance = parseFloat(formatUnits(balance, decimals));
      return formattedBalance.toFixed(4);
    } catch (error) {
      console.error("Error fetching token balance:", error);
      return 0;
    }
  };
  
  const handleSwap = async () => {
    setLoading(true);
    setLoadingNumber(3);
    setLoadingStep(1);
    setLoadingText("Approving GBL transaction.");
  
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
  
    // Approve the contract to spend tokens on behalf of the user
    const tokenContract = new Contract(SETTINGS.globalTokenAddress, ERC20_ABI, signer);
    const amountInWei = parseEther(swapTokenAmount.toString());
    const allowanceToken = await tokenContract.allowance(selectedAccount, SETTINGS.recycleSwapAddress);
  

      const approveTx = await tokenContract.approve(SETTINGS.recycleSwapAddress, amountInWei);
      await approveTx.wait(); // Wait for transaction confirmation
  
      setLoadingStep(2);
      setLoadingText("Approving token transaction.");
    
  
    // Approve recycle token if necessary
    const recTokenContract = new Contract(selectedRecycleToken.address, ERC20_ABI, signer);
    const decimals = await recTokenContract.decimals();
    const recAmountInWei = parseUnits(recycleAmount.toString(), decimals);
  
  
      const approveRecTx = await recTokenContract.approve(SETTINGS.recycleSwapAddress, recAmountInWei);
      await approveRecTx.wait();
    
  
    setLoadingStep(3);
    setLoadingText("Opening WaweSwap V1");
  
    // Interact with the main contract for the swap
    const contract = new Contract(SETTINGS.recycleSwapAddress, RecycleSwapABI, signer);
    const fee = await contract.FEE();
    const buySwapTx = await contract.buySwapRecycle(
      amountInWei,
      selectedRecycleToken.address,
      recAmountInWei,
      {
        value: 1000000000000000
      }
    );
  
    await buySwapTx.wait(); // Wait for confirmation
    displayToast("Swap opened.", buySwapTx.hash);
  
    // Reload user swaps and reset form state
    getSwapsForUser(selectedAccount)
      .then(setSwaps)
      .catch((error) => displayToast(error.message, null, true));
  
    setSwapTokenAmount("");
    setSwapRewardAmount("");
    setRecycleAmount("");
    setSwapAvailable(false);
    setLoading(false);
  };
  


  


  const exportSwaps = async () => {
    /*
    const contractStorage = new web3.eth.Contract(RecycleSwapStorageABI, SETTINGS.recycleSwapStorageAddress);
        
    let csvResult = "";
    let swapsArr = [];
  
    // Start from the head of the list
    let currentUid = await contractStorage.methods.headIndex().call();
    let lastIndex = await contractStorage.methods.lastIndex().call();
 
    while (currentUid !== lastIndex) {
      try {
        const swap = await contractStorage.methods.swaps(currentUid).call();
        console.log(currentUid + " / " + lastIndex);
  
        const swapAmount = parseFloat(web3.utils.fromWei(swap.amount, 'ether')).toFixed(0);
        const rewardAmount = parseFloat(web3.utils.fromWei(swap.rewards, 'ether')).toFixed(0);
     
        swapsArr.push([swapAmount, rewardAmount, swap.user]);
        csvResult += currentUid.toString() + ";" 
        + swap.user + ";" 
        + swapAmount + ";" 
        + rewardAmount +  "\n";
        
        // Move to the next swap in the list
        currentUid = swap.next;
      } catch (error) {
        console.log("Iteration error or reached the end:", error);
        break; // Exit the loop in case of error (e.g., end of list)
      }
    }
  
    console.log("swapsArr");
    console.log(JSON.stringify(swapsArr));

        // generate csv file
        console.log(JSON.stringify(swapsArr));
        const currentDateTime = getCurrentDateTimeString();
        const fileName = `swapV1_export_${currentDateTime}.csv`;
        const blob = new Blob([csvResult], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
          */
  
  };



  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year}_${hour}-${minute}-${second}`;
  };

  const getTimeLeft =  (createdAt) => {
    // Convert createdAt to milliseconds for the start time and add 30 days for the end time
    const endTime = new Date((createdAt + 90 * 24 * 60 * 60) * 1000);
   
    // Current time
    const now = new Date();
   
    // Calculate the difference in milliseconds
    const timeLeft = endTime - now;
   
    // Convert time left into days and hours
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
   
    // Return a string with days and hours left
    return `${daysLeft} days and ${hoursLeft} hours left`;
   
           };

           const getSwapsForUser = async (userAddress) => {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
          
            try {
              const contractStorage = new Contract(SETTINGS.recycleSwapStorageAddress, RecycleSwapStorageABI, signer);
          
              // Retrieve user's swaps and reward amount
              const userSwaps = await contractStorage.getSwaps(userAddress);
              const userRewardAmountWei = await contractStorage.gblRewards(userAddress);
              const userRewardAmount = parseFloat(formatEther(userRewardAmountWei));
          
              const swaps = [];
              for (let i = 0; i < userSwaps.length; i++) {
                const amount = parseFloat(formatEther(userSwaps[i][0]));
                const rewards = parseFloat(formatEther(userSwaps[i][1]));
                const maxReward = amount + (amount * 36) / 100;
                const swapIndex = parseInt(userSwaps[i][4]);
                swaps.push({
                  uid: i,
                  swapIndex: swapIndex,
                  amount: amount.toFixed(4),
                  rewards: rewards.toFixed(4),
                  maxReward: maxReward.toFixed(4),
                  timeLeft: getTimeLeft(parseInt(userSwaps[i][3])),
                });
              }
          
              // Calculate fee and net reward based on reward amount
              let feePercentage = 0.005;
              if (userRewardAmount < 100) {
                feePercentage = 0.02; // 2% for less than 100 ether
              } else if (userRewardAmount >= 100 && userRewardAmount < 1000) {
                feePercentage = 0.01; // 1% for 100 to 1000 ether
              }
              const feeAmount = userRewardAmount * feePercentage;
              const netRewardAmount = userRewardAmount - feeAmount;
          
              setUnclaimedRewards(userRewardAmount.toFixed(4));
              setUnclaimedRewardsFee(feeAmount.toFixed(4));
              setUnclaimedRewardsNet(netRewardAmount.toFixed(4));
              const wawe_stakes = await getWaveStakes(userAddress);

              return swaps.reverse();
            } catch (error) {
              console.error("Error fetching swap data:", error);
            }
          };

          const getPriceNetwork = async (tokenAddress) => {
            const routerABI = [
              {
                constant: true,
                inputs: [
                  { internalType: "uint256", name: "amountIn", type: "uint256" },
                  { internalType: "address[]", name: "path", type: "address[]" },
                ],
                name: "getAmountsOut",
                outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
                payable: false,
                stateMutability: "view",
                type: "function",
              },
            ];
          
            const usdtAddress = SETTINGS.usdtAddress[parseInt(networkId)];
            const ethersProvider = new BrowserProvider(provider);
            const routerContract = new Contract("0x10ED43C718714eb63d5aA57B78B54704E256024E", routerABI, ethersProvider);
            const tokContract = new Contract(tokenAddress, ERC20_ABI, ethersProvider);
          
            try {
              const decimals = await tokContract.decimals();
              const amountIn = parseUnits("1", decimals); // Creates an amount equal to `1 * 10^decimals`
          
              const amountsOut = await routerContract.getAmountsOut(amountIn, [tokenAddress, usdtAddress]);
              const priceInUSDT = amountsOut[1];
          
              return priceInUSDT;
            } catch (error) {
              console.error("Error fetching price:", error);
              return 999;
            }
          };
          

          const handleBuyAmountChange = async (e) => {
            const amount = e.target.value;
            setSwapTokenAmount(amount);
            setSwapRewardAmount((parseFloat(amount) + (parseFloat(amount) * 36) / 100).toFixed(4));
            setRecycleAmount(0);
          
            if (amount === "") {
              setSwapAvailable(false);
            } else {
              if (parseFloat(amount) > parseFloat(gblBalance)) {
                setErrorText("Balance too low!");
                setSwapAvailable(false);
              } else {
                setErrorText("");
                if (selectedRecycleToken) {
                  const priceGbl = await getPriceNetwork(SETTINGS.globalTokenAddress);
                  const priceToken = await getPriceNetwork(selectedRecycleToken.address);
          
                  const recycleAM = 
                    ((parseFloat(amount) * parseFloat(formatEther(priceGbl))) / 
                    parseFloat(formatEther(priceToken))) / 100;
          
                  setRecycleAmount(recycleAM.toFixed(4));
          
                  if (parseFloat(recycleAM) > parseFloat(recBalance)) {
                    setErrorText("Balance too low!");
                    setSwapAvailable(false);
                  } else {
                    setErrorText("");
                    setSwapAvailable(true);
                  }
                }
          
                if (parseFloat(amount) > parseFloat(gblBalance)) {
                  setErrorText("GBL balance too low.");
                  setSwapAvailable(false);
                }
              }
            }
          };

          const getWaveStakes = async (userAddress) => {
            try {
              const ethersProvider = new BrowserProvider(provider);
              const signer = await ethersProvider.getSigner();
              const contract = new Contract(
                SETTINGS.recycleSwapAddress,
                RecycleSwapABI,
                signer
              );
        
              const userStakes = await contract.getUserWaveStakes(userAddress);
              const stakesWithClaimable = await Promise.all(
                userStakes.map(async (stake, index) => {
                  // Fetch claimable amount for each stake
                  const claimableAmount = await contract.getClaimableWaveAmount(
                    userAddress,
                    index
                  );
                  return {
                    amount: formatUnits(stake.amount.toString(), 18),
                    startTime: new Date(
                      parseInt(stake.startTime.toString()) * 1000
                    ).toLocaleString(),
                    claimedAmount: formatUnits(stake.claimedAmount.toString(), 18),
                    claimableAmount: parseFloat(
                      formatUnits(claimableAmount.toString(), 18)
                    ).toFixed(6), // Added claimable amount
                  };
                })
              );
        
              setWaveStakes(stakesWithClaimable);
            } catch (error) {
              console.error("Error fetching stakes:", error);
            }
          };
          const claimWaveStake = async (stakeIndex) => {
            try {
              const ethersProvider = new BrowserProvider(provider);
              const signer = await ethersProvider.getSigner();
              const contract = new Contract(
                SETTINGS.recycleSwapAddress,
                RecycleSwapABI,
                signer
              );
        
              const tx = await contract.claim(stakeIndex);
              await tx.wait();
              alert("Claim successful!");
              getSwapsForUser(selectedAccount)
        .then(setSwaps)
        .catch((error) => console.error("Error fetching swaps:", error));
            } catch (error) {
              console.error("Error claiming stake:", error);
            }
          };
        
          const closeSwap = async (_swapIndex) => {
            setLoading(true);
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            try {
              const contract = new Contract(
                SETTINGS.recycleSwapAddress,
                RecycleSwapABI,
                signer
              );
        
              const tx = await contract.openWaveStake(_swapIndex, {
                value: 1000000000000000
              });
         
              await tx.wait(); // Wait for the transaction to be confirmed
              getSwapsForUser(selectedAccount)
        .then(setSwaps)
        .catch((error) => console.error("Error fetching swaps:", error));
              handleCloseCollectModal();
              setLoading(false);
              displayToast("Swap closed.", tx.hash);
            } catch (error) {
              console.error("Swap close failed", error);
              setLoading(false);
            }
          };
          

useEffect(() => {
    const getFee = async () => {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
  
      // Fetching the treasury fee using js
      const tokenSwapContract = new Contract(SETTINGS.tokenSwapAddress, tokenSwapABI, signer);
      const result = await tokenSwapContract.treasuryPaymentWithdrawFee();
      setFee(formatEther(result));
  
      // Fetch GBL balance
      setGblBalance(await getBalanceERC(SETTINGS.globalTokenAddress));
  
      // Check allowance for GBL token
      const tokenContract = new Contract(SETTINGS.globalTokenAddress, ERC20_ABI, signer);
   
    };
  
    if (selectedAccount && !stakesLoaded && parseInt(networkId) == 56) {
      setStakesLoaded(true);
      setTokens(SETTINGS.tokensDataV1.sort((a, b) => (a.price > b.price ? -1 : 1)));
      getFee();
      
      getSwapsForUser(selectedAccount)
        .then(setSwaps)
        .catch((error) => console.error("Error fetching swaps:", error));
    }
  }, [selectedAccount, numOfStakes, stakes, swaps, networkId]);


  const handleSwitchNetwork = async () => {
    await switchNetwork(56);
   
};


  if (networkId != "56") {
    return (
        <div className=" text-center">
            <div className="network-switch-section" style={{marginTop:"150px"}}>
             
                  <p>Switch to BSC network:</p>
                  <Button className="refresh-button hero-button " variant="primary" onClick={handleSwitchNetwork}>
                    Switch to BSC Network</Button>
                </div>
        </div>
    );
}
  
  return (
   
    

    
    
    <div
    className="waweSwap page-container"
    style={{ marginBottom: "100px", marginTop: "50px" }}
  >
        {loading && (
      <div className="gameLoader">
        <br />
        <br />
        <br />

        <br />
        <div className="gameSpinner">
        <Spinner animation="border" role="status" className="loaderBig" />
        <center>
                        <p>
                          <b>
                            {loadingStep} / {loadingNumber}
                          </b>{" "}
                          <br /> {loadingText}
                        </p>
                      </center>
        </div>
        </div>
    )}
          <ToastNotification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} url={toastUrl} error={toastError} />
       
        <div className="row justify-content-center">
           

            <div className="col-12 col-md-8 col-lg-6 ">
                   {selectedAccount &&(<>
          {selectedAccount.toLowerCase() ===
              SETTINGS.ownerAddress.toLowerCase() && (
                <button onClick={exportSwaps}>Export</button>
                
              )}</>
              )}
       <div className="card">
       
        <div className="card-body text-center">
        <InfoModal
                      link="https://docs.waveswaps.com/swaps/v1-gbl-bot"
                      message={
                        <>
                        <strong style={{ color: "#0b4182" }}>The expired V1 GBL Bot feature allowed users to leverage GBL as liquidity on selected networks.</strong>{" "}<br />

                        This mechanism revitalized tokens from decentralized exchanges (DEX) and allocated them to the Recycle pool. With the feature now closed, all orders—both active and pending—have been fully executed, and users are eligible to claim their earned balances.
                        <br />
                        While new V1 GBL Bot orders can no longer be initiated, the successful execution of all orders highlights the transparency and immutable nature of blockchain transactions.
                      </>
                      }
                    />

                      <h3 className="text-center" style={{ color: "#19c676", fontWeight: "500" }}>
                        V1 GBL Bot FEATURE HAS EXPIRED!
                      </h3>
                      <h4 className="text-center" style={{ color: "#19c676", fontWeight: "500" }}>
                        ALL ORDERS HAVE BEEN CLOSED 100%, AND YOU ARE ELIGIBLE TO CLAIM YOUR SWAPPING INCOME IN "GBL".
                      </h4>
 {selectedAccount.toLowerCase() == "0x2ca2584F1565489EF9598a2C8610Ed40f06f251F".toLowerCase() && (
   <>
        <div className='formBox'>
              </div>
        <div className='formBox'>
              <label className="form-label">GBL liquidity</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={swapTokenAmount}
                onChange={handleBuyAmountChange}
              />
              <label className="form-label"><small>Balance: </small> {gblBalance}</label>
             
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect"className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>

    
          <Dropdown.Item key="GBL">
            <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px' }} />GBL
          </Dropdown.Item>
    

      </Dropdown.Menu>
    </Dropdown>
  </div>
</div>

              </div>
<br />
              <div className='formBox'>
              <label className="form-label">Recycle token</label>
<div className='row'>
  <div className='col-6 col-md-8 col-lg-8'><input
                id="tokenAmount"
                type="text"
                className="form-control"
                placeholder="0"
                value={recycleAmount}
                disabled
              />
              <label className="form-label"><small>Balance: </small> {recBalance}</label>
             
              </div>
  <div className='col-6 col-md-4 col-lg-4'>
  <Dropdown>
      <Dropdown.Toggle variant="primary" id="tokenSelect" className='dropdownStyle'>
      <img src={SETTINGS.tokenIcons[selectedLabelRecycle]} alt="" style={{ width: '20px', marginRight: '10px' }} />{selectedLabelRecycle}
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownMenuStyle'>
  {tokens && tokens.length > 0 ? (
    tokens.map((token) => (
      <Dropdown.Item key={token.address} onClick={() => handleSelectRecycle(token)}>
        <img src={SETTINGS.tokenIcons[token.symbol]} alt="" style={{ width: '20px', marginRight: '10px' }} />{token.symbol}
      </Dropdown.Item>
    ))
  ) : (
    <Dropdown.Item disabled>No tokens available</Dropdown.Item>
  )}
</Dropdown.Menu>

    </Dropdown>
  </div>
</div>
<hr />
<div className='row'>
<div className='col-1'><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px' }} /></div>
      <div className='col-5'><label className="form-label">Exit Value <small className="smallText">(+36%)</small>  </label></div>
      <div className='col-6'><label className="form-label"> <small className="smallTitle">{swapRewardAmount} </small><small>GBL</small></label></div>

              </div>
              <hr />
              </div>
              <div className="d-grid gap-2"  style={{textAlign:"left"}}>
            <small className="smallText text-left" style={{paddingBottom:"20px"}}>
              By clicking the button "Swap", you agree with WaweSwaps <a href="https://docs.waveswaps.com/ws-official/terms-and-conditions" target="_blank">Terms and Conditions.</a>

                </small>
              
              </div>
              <div className="d-grid gap-2"  style={{textAlign:"left"}}>
            {swapAvailable ? ( <div>

                        
                        <button className="large-button-blue" type="button" onClick={handleSwap}>Swap</button>
                        
                        </div>):(
                <div>
                <button className="large-button-blue-disabled" type="button" >Swap</button>
                <center><div className="error-text error-text" >{errorText}</div></center>
                </div>
            )}
            </div>
            <div className="d-grid gap-2">
            <small className="smallText text-left" style={{paddingTop:"20px"}}>
            Swapping income is distributed when enough total BNB GAS has accumulated to execute the distribution function within the V1 smart contract.
                </small>
</div>
</>)}
        </div>
        </div>
        <br />
       <div className="row" style={{textAlign:"center"}}>


<div className="col-3 col-lg-4"><small className='smallTitle'>Income:</small></div>
<div className="col-6 col-lg-4"><img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '20px', marginRight: '10px', marginTop:"-3px" }} /><small> {unclaimedRewards}</small> <small className='smallText'>GBL</small></div>
<div className="col-3 col-lg-4"><button type="button" className="mediumButton" style={{marginTop:"0px"}}  onClick={handleShowCollectModal}>Claim</button></div>
<div className="col-12">


</div>

</div>
<div>
              {waveStakes.length > 0 ? (
                <>
    <h5 className="mb-3 mt-5">WAVE Claim:</h5>
    <div className="col-12">
      <small className="smallText text-left">
      Your list of migrated and canceled V1 GBL Bots, releasing a fixed, proportional WAVE amount daily over 90 days.
      </small><br /><br />
    </div>
                  <Accordion defaultActiveKey="0">
                    {waveStakes.map((stake, index) => (
                      <Card key={index}>
                        <Card.Header>
                          <div className="row">
                            <div className="col-5">
                              <small
                                className="smallTitleSmall"
                                style={{ lineHeight: "30px", marginTop: "5px" }}
                              >
                                <img
                                  src={SETTINGS.tokenIcons["WAVE"]}
                                  alt=""
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                    marginTop: "-3px",
                                  }}
                                />{" "}
                                {stake.amount} WAVE
                              </small>
                            </div>
                            <div className="col-5">
                              <small
                                className="smallTitleSmall"
                                style={{ lineHeight: "30px", marginTop: "5px" }}
                              >
                                <img
                                  src={SETTINGS.tokenIcons["WAVE"]}
                                  alt=""
                                  style={{
                                    width: "15px",
                                    marginRight: "10px",
                                    marginTop: "-3px",
                                  }}
                                />{" "}
                                Claimable: {stake.claimableAmount} WAVE
                              </small>
                            </div>
                            <div className="col-1">
                              <ContextAwareToggle eventKey={String(index)} />
                            </div>
                          </div>
                        </Card.Header>

                        <Accordion.Collapse eventKey={String(index)}>
                          <Card.Body>
                            <div className="row">
                              <div className="col-12 col-lg-6">
                                <label className="form-label">Start Time</label>
                                <br />
                                <small>{stake.startTime}</small>
                              </div>
                              <div className="col-12 col-lg-6">
                                <label className="form-label">
                                  Claimed Amount
                                </label>
                                <br />
                                <small>{stake.claimedAmount} WAVE</small>
                              </div>
                              <div className="col-12">
                                {parseFloat(stake.claimableAmount) > 0 && (
                                  <button
                                    type="button"
                                    className="hero-button"
                                    onClick={() => claimWaveStake(index)}
                                    style={{ marginTop: "10px" }}
                                  >
                                    Claim WAVE
                                  </button>
                                )}
                              </div>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))}
                  </Accordion>
                </>
              ) : (
                <></>
              )}
            </div>
            <h5 className="mb-3 mt-5">V1 GBL Bot(s):</h5>
        <Accordion defaultActiveKey="0">
        {swaps && swaps.length > 0 ? (
  swaps.map((swap, index) => (
    <Card key={index}>
      <Card.Header>
        <div className="row ">
          <div className="col-5">
            <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}>
              <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop: "-3px" }} />
              {swap.rewards} <small className='smallText'> GBL</small>
            </small>
          </div>

          <div className="col-1"></div>
          
          <div className="col-5">
            <small className='smallTitleSmall' style={{ lineHeight: '30px', marginTop: "5px" }}>
              <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px', marginTop: "-3px" }} />
              {swap.amount === 0 ? (<>{swap.rewards}</>) : (<>{swap.maxReward}</>)}
              <small className='smallText'> GBL</small>
            </small>
          </div>

          <div className="col-1">
            <ContextAwareToggle eventKey={String(index)}></ContextAwareToggle>
          </div>
          
          <div className="col-12">
            {swap.amount === 0 ? (
              <ProgressBar now={100} min={0} max={100} striped variant="info" className='swapProgress'></ProgressBar>
            ) : (
              <ProgressBar now={swap.rewards} min={0} max={swap.maxReward} striped variant="info" animated className='swapProgress'></ProgressBar>
            )}
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={String(index)}>
        <Card.Body>
          <div className="row">
            <div className="col-12 col-lg-6">
              <label className="form-label">Liquidity </label><br />
              <small>
                <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />
                {swap.amount} GBL
              </small>
              <br /><br />
            </div>
            <div className="col-12 col-lg-6">
              <label className="form-label">Recycling income </label><br />
              <small>
                <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />
                {swap.rewards} GBL
              </small>
              <br /><br />
            </div>
            <div className="col-12 col-lg-6">
              <label className="form-label">Exit value <small className="smallText">(+36%)</small> </label><br />
              <small>
                <img src={SETTINGS.tokenIcons["GBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />
                {swap.maxReward} GBL
              </small>
              <br />
            </div>
            <div className="col-12 col-lg-6">
              <label className="form-label">Earned VGBL </label><br />
              <small>
                <img src={SETTINGS.tokenIcons["VGBL"]} alt="" style={{ width: '15px', marginRight: '10px' }} />
                {swap.amount} VGBL
              </small>
              <br />
            </div>
            <div className="col-12 col-lg-12">
                          <label className="form-label">Migrate to WAVE </label>
                          <br />
                          <button
                            type="button"
                            className="claimButton"
                            style={{ marginTop: "0px", marginLeft:"0px" }}
                            onClick={() => closeSwap(swap.swapIndex)} // Wrap in an anonymous function
                          >
                             Close swap and claim {parseFloat(swap.sellAmount) / 500} WAVE through 100 days.
                          </button>
                          <br />
                          <br />
                        </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  ))
) : (
  <p>No swaps available</p> // Placeholder message if there are no swaps
)}



      
    
    </Accordion>
        </div>
     
        </div>
        
    
        <div className="row">
            
          
            <div className="col-12 col-lg-6 text-center">
            <Modal show={showCollectModal} onHide={handleCloseCollectModal}>
                    <Modal.Header closeButton>
                      <Modal.Title><center>Claim rewards</center></Modal.Title>
                      
                    </Modal.Header>
                    <Modal.Body>
            <div className="card ">
                  <div className="card-body text-center">
                   
                    <small>Available GBL Rewards:</small>
                    <br />
                <input
                    type="text"
                    value={unclaimedRewards}
                    disabled
                    style={{width:"100%", textAlign:"center"}}
                />
                <br /><br />
                    <small>Amount to Claim:</small>
                    <br />
                <input
                    type="text"
                    value={unclaimedRewardsNet}
                    disabled
                    style={{width:"100%", textAlign:"center"}}
                />
                <br /><br />
                    <small>Claim Income Processing Fee:</small>
                    <br />
                <input
                    type="text"
                    value={unclaimedRewardsFee}
                    disabled
                    style={{width:"100%", textAlign:"center"}}
                />
                
                <br />

                <br />
                {unclaimedRewards > 0 ? (<>
                {loading ? (
                          <SpinnerBox><Spinner animation="border" variant="primary" /></SpinnerBox>
                        ) : (<button className="large-button-blue" type="button" onClick={collectIncome}>Claim</button>)}
                        </> ) : (null)}
                        
           
            </div>
            </div>
            <br />
            <small className="smallText" style={{textAlign:"left"}}>
                <b style={{float:"left"}}>Reward Claiming Fee Requests:</b><br />
<ul >
<li>Up to 99 GBL: 2%</li>
<li>From 100 to 999 GBL: 1%</li>
<li>1000 GBL onward: 0.5%</li>
</ul>
                  </small>
            </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                  </Modal>

            </div>

       

     


        
</div>
</div>
  );
};

export default SwapV1BNB;
